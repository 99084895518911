<template>
  <!--Header start-->
    <header class="header">
      <!--top mobile menu start-->
      <div class="top-menu bg-white">
        <div class="container">
        </div>
      </div>
      <!-- end top mobile menu -->

      <!-- top menu -->
      <div class="mobile-sticky fs-6 bg-primary">
        <div class="container">
          <!--Navbar-->
          <nav class="navbar navbar-expand-lg navbar-dark px-0 ">
            <!--Hamburger button-->
            <a id="showStartPush" aria-label="sidebar menu" class="navbar-toggler sidebar-menu-trigger side-hamburger border-0 px-0" href="javascript:;">
              <span class="hamburger-icon">
                <span></span><span></span><span></span><span></span>
              </span>
            </a>
            <!-- Mobile logo -->
            <router-link to="/">
              <img class="mobile-logo img-fluid d-lg-none mx-auto" src="../../assets/img/tagpressWhit.png" alt="موقع ويب الشعار">
            </router-link>
            <div id="navbarTogglerDemo" class="collapse navbar-collapse">
              <!-- Time -->
              <div class="date-area d-none d-lg-block">
                <time class="navbar-text me-2" datetime="2019-10-28">{{ timestamp }}</time>
              </div>
              <!--Top left menu-->
              <ul class="navbar-nav">
                <li class="nav-item"><router-link class="nav-link active arabic-kufi" to="/">الصفحة الرئيسية</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/About">معلومات عنا</router-link></li>
                <!-- <li class="nav-item"><router-link class="nav-link" to="/Contact">اتصل بنا</router-link></li> -->
              </ul>
            </div>
              <!-- <a class="" v-if="showHeader"></a> -->
              <a class="login-btn1 arabic-kufi pointer" v-if="showHeader"><router-link style="vertical-align: middle inherit;color: #fff;" to="/Unsubscribe" >الغاء الاشتراك</router-link></a>
              <a class="login-btn arabic-kufi pointer" v-else><a @click="Subscrib()" style="vertical-align: middle inherit;color: #fff;">اشتراك</a></a>
              <a class="login-btn pointer" style="width: 30%; border-radius: 0px; background-color: transparent; padding-top: 3px;height: auto;">
                <img class="header-logo img-fluid mb-2 scrMob"  src="../../assets/img/zain_gray.png" alt="Header Zain">
              </a>
          </nav>
        </div>
      </div>
      <!--end top menu-->

      <!--start second header-->
      <div class="second-header">
        <div class="container">
          <div class="row">
            <!--Left content logo-->
            <div class="col-lg-4">
              <div id="main-logo" class="main-logo my-2 my-lg-4 d-none d-lg-block">
                <router-link class="navbar-brand" to="/">
                  <img class="img-fluid" src="../../assets/img/tagd.png" alt="موقع ويب الشعار">
                </router-link>
              </div>
            </div>
            <!--Right content ads-->
            <div class="col-lg-8">
              <div class="my-2 my-md-3 my-lg-4 d-none d-md-block text-center">
                <a href="#" target="_blank">
                  <!-- <img class="img-fluid bg-light" src="../assets/img/728-demo.png" alt="مساحة المعلن"> -->
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--End second header-->
    </header>
    <!--End header-->
    <!--Main menu-->
    <div id="showbacktop" class="showbacktop full-nav bg-white border-none border-lg-1 border-bottom shadow-b-sm py-0">
      <div class="container">
        <nav id="main-menu" class="main-menu navbar navbar-expand-lg navbar-light px-2 px-lg-0 py-0">
          <!--Navbar menu-->
          <div id="navbarTogglerDemo1" class="collapse navbar-collapse hover-mode">
            <!-- logo in navbar -->
            <div class="logo-showbacktop">
              <router-link to="/">
                <img class="img-fluid" src="../../assets/img/tagd.png" alt="موقع ويب الشعار">
              </router-link>
            </div>
            <!--left main menu start-->
            <ul id="start-main" class="navbar-nav main-nav navbar-uppercase first-start-lg-0 arabic-kufi">
              <li class="nav-item "><router-link class="nav-link " to="/">الصفحة الرئيسية</router-link></li>
              <!--dropdown menu-->
              <li class="nav-item dropdown">
                <a id="navbarDropdown1" class="nav-link dropdown-toggle " role="button" data-bs-toggle="dropdown" aria-expanded="false" >أخبار</a>
                <!--link-->
                <ul class="dropdown-menu arabic-kufi" aria-labelledby="navbarDropdown1">
                  <li><router-link class="dropdown-item" to="/Policy">سياسية</router-link></li>
                  <li><router-link class="dropdown-item" to="/Mix">منوعات</router-link></li>
                  <!-- <li><router-link class="dropdown-item" to="Mix">تقارير</router-link></li> -->
                  <li><router-link class="dropdown-item" to="/Health">صحة</router-link></li>
                  <li><router-link class="dropdown-item" to="/Technology">علوم وتكنلوجيا</router-link></li>
                </ul>
              </li><!--end dropdown menu-->
              <li class="nav-item"><router-link class="nav-link" to="/Infographic"> اخبار فيديو</router-link></li>
              <!-- <li class="nav-item"><router-link class="nav-link" to="/Caricature">الكاركاتير</router-link></li> -->
              <li class="nav-item"><router-link class="nav-link" to="/Article">مقالات</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/Economie">اقتصاد</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/Press">الصحافة</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/Sport">رياضية</router-link></li>
              <li class="nav-item"><router-link class="nav-link" to="/Accident">حوادث</router-link></li>
              
              <li class="nav-item dropdown" v-if="showHeader">
                <a id="navbarDropdown1" class="nav-link dropdown-toggle " role="button" data-bs-toggle="dropdown" aria-expanded="false" >{{ $cookie.getCookie("msisdn") }}</a>
                <!--link-->
                <ul class="dropdown-menu arabic-kufi" aria-labelledby="navbarDropdown1">
                  <li><router-link class="dropdown-item" to="/Unsubscribe">الغاء الاشتراك</router-link></li>
                </ul>
              </li>

              <li class="nav-item dropdown" v-else>
                <a id="navbarDropdown1" class="nav-link dropdown-toggle " role="button" data-bs-toggle="dropdown" aria-expanded="false" >الحساب</a>
                <!--link-->
                <ul class="dropdown-menu arabic-kufi" aria-labelledby="navbarDropdown1">
                  <li><router-link class="dropdown-item" to="/LoginUser">تسجيل دخول</router-link></li>
                  <li><a @click="Subscrib()" class="dropdown-item pointer">اشتراك</a></li>
                </ul>
              </li>
            </ul>
            <!--end left main menu-->

            <!--Search form-->
            <!-- <div class="navbar-nav ms-auto d-none d-lg-block">
              <div class="search-box"> -->
                <!--hide search-->
                <!-- <div class="search-menu no-shadow border-0 py-0">
                  <form class="form-src form-inline" action="#">
                    <div class="input-group">
                      <input type="text" class="form-control end-0" name="keywords" value="" placeholder="بحث ..." aria-label="search">
                       <span class="icones">
                          <svg xmlns="http://www.w3.org/2000/svg" class="ms-n4" width="1rem" height="1rem" fill="currentColor" viewBox="0 0 512 512"><path d="M221.09,64A157.09,157.09,0,1,0,378.18,221.09,157.1,157.1,0,0,0,221.09,64Z" style="fill:none;stroke:currentColor;stroke-miterlimit:10;stroke-width:32px"/><line x1="338.29" y1="338.29" x2="448" y2="448" style="fill:none;stroke:currentColor;stroke-linecap:round;stroke-miterlimit:10;stroke-width:32px"/></svg>
                       </span>
                    </div>
                  </form>
                </div> -->
                <!--end hide search-->
              <!-- </div>
            </div> -->
            <!--end search form-->
          </div>
          <!--End navbar menu-->
        </nav>
      </div>
    </div>
    <!-- End main menu -->

    <!-- sidebar mobile menu -->
    <div class="mobile-side">
      <!--Left Mobile menu-->
      <div id="back-menu" class="back-menu back-menu-start">
        <span class="hamburger-icon open">
          <svg class="bi bi-x" width="2rem" height="2rem" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z" clip-rule="evenodd"></path>
            <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z" clip-rule="evenodd"></path>
          </svg>
        </span>
      </div>

      <nav id="mobile-menu" class="menu-mobile d-flex flex-column push push-start shadow-r-sm bg-white">
        <!-- mobile menu content -->
        <div class="mobile-content mb-auto">
          <!--logo-->
          <div class="logo-sidenav p-2">
            <router-link to="/">
              <img src="../../assets/img/tagd.png" class="img-fluid" alt="شعار">
            </router-link>
          </div>
          <!--navigation-->
          <div class="sidenav-menu">
            <nav class="navbar navbar-inverse">
              <ul id="side-menu" class="nav navbar-nav list-group list-unstyled side-link">
                <li class="menu-item nav-item arabic-kufi"><router-link class="nav-link active list-group-item" to="/">الصفحة الرئيسية</router-link></li>

                <!-- dropdown menu start -->
                <li class="menu-item menu-item-has-children dropdown mega-dropdown nav-item arabic-kufi">
                  <a class="dropdown-toggle nav-link list-group-item" role="button" data-bs-toggle="dropdown" aria-expanded="false" id="menu-11">أخبار</a>
                  <!-- dropdown content -->
                  <ul class="dropdown-menu border-bottom-last-0" aria-labelledby="menu-11" role="menu">
                    <li class="menu-item nav-item">
                      <router-link class="dropdown-item list-group-item" to="/Policy">سياسية</router-link>
                      <router-link class="dropdown-item list-group-item" to="/Mix">منوعات</router-link>
                      <!-- <router-link class="dropdown-item list-group-item" to="/">تقارير</router-link> -->
                      <router-link class="dropdown-item list-group-item" to="/Health">صحة</router-link>
                      <router-link class="dropdown-item list-group-item" to="/Technology">علوم وتكنلوجيا</router-link>
                    </li>
                  </ul>
                </li><!-- end dropdown menu -->
                <li class="menu-item nav-item arabic-kufi"><router-link class="nav-link list-group-item" to="/Infographic"> اخبار فيديو</router-link></li>
                <!-- <li class="menu-item nav-item arabic-kufi"><router-link class="nav-link list-group-item" to="/Caricature">الكاركاتير</router-link></li> -->
                <li class="menu-item nav-item arabic-kufi"><router-link class="nav-link list-group-item" to="/Article">مقالات</router-link></li>
                <li class="menu-item nav-item arabic-kufi"><router-link class="nav-link list-group-item" to="/Economie">اقتصاد</router-link></li>
                <li class="menu-item nav-item arabic-kufi"><router-link class="nav-link list-group-item" to="/Press">الصحافة</router-link></li>
                <li class="menu-item nav-item arabic-kufi"><router-link class="nav-link list-group-item" to="/Sport">رياضية</router-link></li>
                <li class="menu-item nav-item arabic-kufi"><router-link class="nav-link list-group-item" to="/Accident">حوادث</router-link></li>

                <li class="menu-item menu-item-has-children dropdown mega-dropdown nav-item arabic-kufi" v-if="showHeader">
                  <a class="dropdown-toggle nav-link list-group-item" role="button" data-bs-toggle="dropdown" aria-expanded="false" id="menu-11">{{ $cookie.getCookie("msisdn") }}</a>
                  <!-- dropdown content -->
                  <ul class="dropdown-menu border-bottom-last-0" aria-labelledby="menu-11" role="menu">
                    <li class="menu-item nav-item">
                      <router-link to="/Unsubscribe" class="dropdown-item list-group-item">الغاء الاشتراك</router-link>
                    </li>
                  </ul>
                </li>
                <li class="menu-item menu-item-has-children dropdown mega-dropdown nav-item arabic-kufi" v-else>
                  <a class="dropdown-toggle nav-link list-group-item" role="button" data-bs-toggle="dropdown" aria-expanded="false" id="menu-11">الحساب</a>
                  <!-- dropdown content -->
                  <ul class="dropdown-menu border-bottom-last-0" aria-labelledby="menu-11" role="menu">
                    <li class="menu-item nav-item">
                      <router-link class="dropdown-item list-group-item" to="/LoginUser">تسجيل دخول</router-link>
                      <a @click="Subscrib()" class="dropdown-item list-group-item pointer">اشتراك</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!-- copyright mobile sidebar menu -->
        <!-- <div class="mobile-copyright mt-5 text-center arabic-kufi">
          <p>حقوق النشر <a href="https://www.byte.sd/">Byte Co.Ltd</a> - جميع الحقوق محفوظة</p>
        </div> -->
      </nav>
    </div>
    <!-- end sidebar mobile menu -->
</template>

<script>
import { ref } from '@vue/reactivity';
import { useRoute, useRouter } from 'vue-router';
import { HTTPDSP } from '@/Api/http-dsp';
import { watchEffect } from '@vue/runtime-core';
import { useCookie } from 'vue-cookie-next'
export default {
   name: 'AppHeader',
   data() {
     return {
        timestamp: ""
     }
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()
        const showHeader = ref(true);
        const search = ref([]);
         
        watchEffect(() => {
            if (cookie.isCookieAvailable("status") && cookie.isCookieAvailable("msisdn")) {
                HTTPDSP.post("DSPCheckLogin.php?msisdn="+cookie.getCookie("msisdn")).then((res) => {
                    if (res.data.status == 1 ) {
                        return;
                    } else {
                        cookie.removeCookie("minutes");
                        cookie.removeCookie("msisdn");
                        cookie.removeCookie("status");
                    }
                });
                showHeader.value = true;
            } else {
               showHeader.value = false;
            }
        });  

        const Subscrib = () => {
            window.open("https://dsplp.sd.zain.com/?p=3885574267", "_self");
        }

        return { showHeader, Subscrib };
    },
    methods: {
        getNow: function() {
            const today = new Date();
            const date = today.toLocaleDateString('ar-EG-u-nu-latn',{weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'});
                    // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date ;
            this.timestamp = dateTime;
            }
        },
   mounted() {
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', '/assets/js/theme-rtl.js')
    document.head.appendChild(externalScript)
  },
}
</script>

<style scoped>
.arabic-kufi {
  font-family: 'Zain_Regular', serif;
}
.pointer {
  cursor: pointer;
}
.ms-n4 {
   height: 1rem;
   width: 1rem;
 }
 .svg-2rem {
    height: 2rem;
    width: 2rem;
 }
 .login-btn {
  font-size: 1rem;
  border: none;
  height: 30px;
  width: 17%;
  border-radius: 3px;
  background-color: #C7CDD97D;
  /* font-family: "Cairo", sans-serif; */
  color: #ffffff;
  text-align: center;
  padding-top: 3px;
}
 .login-btn1 {
  font-size: 1rem;
  border: none;
  height: 30px;
  width: 17%;
  border-radius: 4px;
  background-color: #C7CDD97D;
  /* font-family: "Cairo", sans-serif; */
  color: #ffffff;
  text-align: center;
  padding-top: 3px;
}

@media (max-width:768px){
  .login-btn1 {
    width: 27%;
  }
}
@media (max-width:412px){
  .login-btn1 {
    width: 32%;
  }
}

@media (max-width:360px){
  .login-btn1 {
    width: 35%;
  }
}

</style>